<template>
    <div>

        <!-- Hero Section -->
        <section class="hero">
            <div class="container text-center">

                <h1 class="text-white">
                    PharmaConnect Tunisie</h1>
                <h3 class="text-white">La Révolution Digitale dans la Pharmacie Tunisienne</h3>
                <br>
                <br>
                <a :href="$router.resolve({ path: '/login' }).href" class="cta-button" target="_blank"
                    rel="noopener noreferrer">
                    Accéder Au Portail
                </a>
            </div>


        </section>

        <!-- Introduction Section -->
        <section class="introduction text-center py-5">
            <div class="container">
                <h2>C'est Quoi PharmaConnect ?</h2>
                <p class="lead">PharmaConnect est bien plus qu'une simple plateforme. C'est une solution révolutionnaire
                    qui
                    connecte les pharmaciens, les grossistes et les fournisseurs de produits pharmaceutiques et
                    parapharmaceutiques en Tunisie. Nous facilitons la communication, la gestion des commandes et
                    l'accès à des
                    informations précieuses du secteur.
                </p>
            </div>
        </section>

        <!-- Key Benefits Section -->
        <section class="key-benefits">
            <div class="container">
                <h2 class="agent">Pharmaciens</h2>
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/order.png')"
                                alt="Pharmaciens Commande Simplifiée" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Commande Simplifiée</h2>
                        <p>Passez des commandes rapidement et efficacement chez n'importe quel grossiste en un seul
                            endroit.</p>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/documentation.png')"
                                alt="Pharmaciens Suivi des Documents" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Suivi des Documents</h2>
                        <p>Consultez et imprimez vos factures/avoirs/retouts et bons de livraison en temps réel.</p>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/notification.png')"
                                alt="Pharmaciens Être à jour" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Être à jour</h2>
                        <p>Consultez les circulaires, les annances et les nouveautés du secteur directement sur la
                            plateforme.</p>
                    </div>
                </div>

                <hr>

                <h2 class="agent">Fournisseurs</h2>
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/in-stock.png')"
                                alt="Fournisseurs Catalogues Des Articles" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Catalogues Des Articles</h2>
                        <p>Ajoutez/supprimer et modifier vos articles pour une meilleure
                            commercialisation sur pharmaconnect.</p>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/checklist.png')"
                                alt="Fournisseurs Suivi des Articles" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Suivi des Articles</h2>
                        <p>Obtenez des statistiques sur les ventes de vos articles chez tous les grossites en un seul
                            endroit.</p>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/pie-chart.png')"
                                alt="Fournisseurs Statistiques en Temps Réel" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Statistiques en Temps Réel</h2>
                        <p>Accédez aux données de vente en temps réel pour une meilleure analyse.</p>
                    </div>
                </div>

                <hr>

                <h2 class="agent">Grossistes</h2>
                <div class="row text-center">
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/transformation.png')"
                                alt="Grossistes Gestion des Commandes" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Gestion des Commandes</h2>
                        <p>Suivez et gérez les commandes en ligne et évitez les transactions téléphoniques
                            réactionnaires.</p>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/analysis.png')"
                                alt="Grossistes Statistiques de Vente" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Statistiques de Vente</h2>
                        <p>Accédez à des statistiques avancées sur les ventes pour une meilleure analyse du marché et
                            des produits.
                        </p>
                    </div>
                    <div class="col-md-4">
                        <div class="icon-container">
                            <b-img :src="require('@/assets/images/website/release.png')"
                                alt="Grossistes Gestion des Réclamations" height="128" width="128"></b-img>
                        </div>
                        <h2 class="utilities">Gestion des Réclamations</h2>
                        <p>Améliorez la satisfaction des clients en gérant efficacement les réclamations et en assurant
                            un suivi
                            rapide et précis.</p>
                    </div>
                </div>

            </div>
        </section>

        <!-- Blog Section -->
        <section class="blog bg-light-gray">
            <div class="container">
                <h2 class="text-center actuality">Actualités et Nouveautés</h2>
                <div class="row">
                    <div class="col-md-4" v-for="blog in blogs" :key="blog.id">
                        <div class="card">
                            <img v-if="blog.image == undefined || blog.image == null || blog.image == ''"
                                :src="require('@/assets/images/website/400x200.png')" class="card-img-top"
                                alt="Blog Image">
                            <img v-else :src="$serverpath + blog.image" class="card-img-top" alt="Blog Image"
                                height="200" width="400">
                            <div class="card-body">
                                <h5 class="card-title">{{ blog.title }}</h5>
                                <p class="card-text">{{ blog.description }}</p>
                                <div class="d-flex justify-content-between align-items-center">
                                    <router-link v-if="blog.id" :to="'/news/' + blog.id" class="btn btn-primary">Voir
                                        Plus</router-link>
                                    <p class="card-text mb-0"><small class="text-muted">{{ blog.createdAt }}</small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Contact Us Section -->
        <section class="contact text-center py-5">
            <div class="container">
                <h2>Contactez-Nous</h2>
                <p>Avez vous des questions ? N'hésitez pas à nous contacter.</p>
                <form @submit.prevent="sendMessage" class="mx-auto" style="max-width: 600px;">
                    <b-form-group label="Nom" label-for="name">
                        <b-form-input id="name" v-model="contactForm.name" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Email" label-for="email">
                        <b-form-input id="email" type="email" v-model="contactForm.email" required></b-form-input>
                    </b-form-group>
                    <b-form-group label="Message" label-for="message">
                        <b-form-textarea id="message" v-model="contactForm.message" rows="4" required></b-form-textarea>
                    </b-form-group>
                    <b-button type="submit" variant="primary">Envoyer le Message</b-button>
                </form>
            </div>
        </section>

    </div>


</template>

<script>


export default {
    metaInfo() {
        return {
            title: 'PharmaConnect | La Révolution Digitale pour la Pharmacie Tunisienne',
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content: "PharmaConnect est une solution digitale qui connecte les pharmaciens, les grossistes et les fournisseurs de produits pharmaceutiques et parapharmaceutiques en Tunisie."
                },
                {
                    name: 'keywords',
                    content: 'PharmaConnect, Pharmacie, Grossistes, Fournisseurs, Tunisie, Pharmaciens, Plateforme Pharmaceutique, parapharmacie,parapharmaceutique, Commandes en Ligne'
                },
                {
                    name: 'author',
                    content: 'DIGITHINK CONSULTING'
                },
                {
                    property: 'og:title',
                    content: 'PharmaConnect | La Révolution Digitale pour la Pharmacie Tunisienne'
                },
                {
                    property: 'og:description',
                    content: 'PharmaConnect est bien plus qu\'une simple plateforme.C\'est une solution révolutionnaire qui connecte les pharmaciens, les grossistes et les fournisseurs de produits pharmaceutiques et parapharmaceutiques en Tunisie. Nous facilitons la communication, la gestion des commandes et l\'accès à des informations précieuses du secteur.'
                },
                {
                    name: "robots",
                    content: "index, follow",
                },
                {
                    property: "og:image",
                    content: require('@/assets/images/website/home.png'),
                },
                {
                    property: "og:url",
                    content: "https://www.pharmaconnect.net",
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:title",
                    content: "PharmaConnect | La Révolution Digitale pour la Pharmacie Tunisienne",
                },
                {
                    name: "twitter:description",
                    content:
                        "PharmaConnect est bien plus qu'une simple plateforme.C'est une solution révolutionnaire qui connecte les pharmaciens, les grossistes et les fournisseurs de produits pharmaceutiques et parapharmaceutiques en Tunisie. Nous facilitons la communication, la gestion des commandes et l'accès à des informations précieuses du secteur.",
                },
                {
                    name: "twitter:image",
                    content: require('@/assets/images/website/home.png'),
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://www.pharmaconnect.net",
                },
            ],
            script: [
                {
                    type: "application/ld+json",
                    json: {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "PharmaConnect | La Révolution Digitale pour la Pharmacie Tunisienne",
                        "description": "PharmaConnect est bien plus qu'une simple plateforme.C'est une solution révolutionnaire qui connecte les pharmaciens, les grossistes et les fournisseurs de produits pharmaceutiques et parapharmaceutiques en Tunisie. Nous facilitons la communication, la gestion des commandes et l'accès à des informations précieuses du secteur.",
                        "brand": {
                            "@type": "Brand",
                            "name": "PharmaConnect"
                        }
                    },
                },
            ],
        };
    },
    data() {
        return {
            blogs: [
                { image: '', title: '', description: '' },
                { image: '', title: '', description: '' },
                { image: '', title: '', description: '' }
            ],
            contactForm: {
                name: '',
                email: '',
                message: ''
            }
        };
    },
    methods: {
        async sendMessage() {
            await this.$http.post("contact", this.contactForm);
            this.contactForm = {
                name: '',
                email: '',
                message: ''
            };
            this.$swal({
                title: 'Message Envoyé',
                text: 'Votre message a été envoyé, il sera traité bientôt !',
                icon: 'success',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            });
        },
        async loadBlogs() {
            try {
                let response = await this.$http.get("blog/anonymous", { params: { page: 0, size: 3 } });
                this.blogs = response.data.content;
                this.totalElements = response.data.totalElements;
            } catch (error) {
                console.log(error)
            }
        }
    },
    created() {
        this.loadBlogs();
    }
};
</script>

<style scoped>
.hero {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('~@/assets/images/website/home.png') no-repeat center center;
    background-size: cover;
    color: white;
    padding: 200px 0;
}

.cta-button {
    background-color: #7367f0;
    color: #fff;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #28c76f;
}

.icon-container {
    font-size: 2.5rem;
    color: #6c63ff;
}

.bg-light-gray {
    background-color: #e9ecef;
    margin-top: 50px;
}

.agent {
    justify-self: center;
    padding: 50px;
    /* color: #28c76f; */
    font-weight: bold;
}

.utilities {
    padding: 20px;
}

.actuality {
    padding: 50px;
}
</style>